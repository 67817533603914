<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Sistem Administrasi Umum</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>
                Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan
                Fisika
              </h4>
            </div>
            <hr style="height: 1px; background: black" />

            <!-- <ul class="undot">
              <li>Nama Mahasiswa : {{ detail.student_name }}</li>
              <li>NIM : {{ detail.registration_number }}</li>
              <li>Semester ke- : {{ detail.semester_name }}</li>
              <li>Tahun ke- : {{ detail.year_id }}</li>
              <li>Program Studi : {{ detail.study_program_name }}</li>
              <li>Fakultas : {{ detail.faculty_name }}</li>
              <li>Alamat : {{ detail.address }}</li>
              <li>No. Telp/Wa : {{ detail.phone }}</li>
              <li>Alamat Email : {{ detail.email }}</li>
              <li>Jenis Kegiatan : {{ detail.activity_type_name }}</li>
              <li>Dosen Pembimbing I : {{ detail.is_external1_name }}</li>
              <li>Nama : {{ detail.teacher1_name }}</li>
              <li>NIP : {{ detail.teacher1_registration_number }}</li>
              <li>Instansi Asal : {{ detail.teacher1_origin_institution }}</li>
              <li>
                Alamat Instansi : {{ detail.teacher1_address_institution }}
              </li>
              <li>No telphone : {{ detail.teacher1_phone }}</li>
              <li>Email : {{ detail.teacher1_email }}</li>
              <li>Dosen Pembimbing II : {{ detail.is_external2_name }}</li>
              <li>Nama : {{ detail.teacher2_name }}</li>
              <li>NIP : {{ detail.teacher2_registration_number }}</li>
              <li>Instansi Asal : {{ detail.teacher2_origin_institution }}</li>
              <li>
                Alamat Instansi : {{ detail.teacher2_address_institution }}
              </li>
              <li>No telphone : {{ detail.teacher2_phone }}</li>
              <li>Email : {{ detail.teacher2_email }}</li>
              <li>Keterangan : {{ detail.description }}</li>
            </ul> -->

            <table class="table table-borderless">
              <tr style="line-height: 1">
                <td>Nama Mahasiswa</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIM</td>
                <td>:</td>
                <td>{{ detail.registration_number }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Semester ke-</td>
                <td>:</td>
                <td>{{ detail.semester_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Tahun ke-</td>
                <td>:</td>
                <td>{{ detail.year_id }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Program Studi</td>
                <td>:</td>
                <td>{{ detail.study_program_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Fakultas</td>
                <td>:</td>
                <td>{{ detail.faculty_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No. Telp/Wa</td>
                <td>:</td>
                <td>{{ detail.phone }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Email</td>
                <td>:</td>
                <td>{{ detail.email }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Jenis Surat</td>
                <td>:</td>
                <td>Surat Permohonan Dosen Pembimbing Kegiatan</td>
              </tr>
              <tr style="line-height: 1">
                <td>Jenis Kegiatan</td>
                <td>:</td>
                <td>{{ detail.activity_type_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Dosen Pembimbing I</td>
                <td>:</td>
                <td>{{ detail.is_external1_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Nama</td>
                <td>:</td>
                <td>{{ detail.teacher1_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIP</td>
                <td>:</td>
                <td>{{ detail.teacher1_registration_number }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Instansi Asal</td>
                <td>:</td>
                <td>{{ detail.teacher1_origin_institution }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Instansi</td>
                <td>:</td>
                <td>{{ detail.teacher1_address_institution }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No telphone</td>
                <td>:</td>
                <td>{{ detail.teacher1_phone }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Email</td>
                <td>:</td>
                <td>{{ detail.teacher1_email }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Dosen Pembimbing II</td>
                <td>:</td>
                <td>{{ detail.is_external2_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Nama</td>
                <td>:</td>
                <td>{{ detail.teacher2_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIP</td>
                <td>:</td>
                <td>{{ detail.teacher2_registration_number }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Instansi Asal</td>
                <td>:</td>
                <td>{{ detail.teacher2_origin_institution }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Instansi</td>
                <td>:</td>
                <td>{{ detail.teacher2_address_institution }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No telphone</td>
                <td>:</td>
                <td>{{ detail.teacher2_phone }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Email</td>
                <td>:</td>
                <td>{{ detail.teacher2_email }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Keterangan</td>
                <td>:</td>
                <td>{{ detail.description }}</td>
              </tr>
            </table>

            <!-- <div class="d-flex justify-content-start mt-2">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  image-container
                "
              >
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
  },
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>